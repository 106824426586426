<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
        <div class="content-main">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane v-for="(item, index) in tabName" :key="index" :label="item.label" :name="item.name" @click="changeView(item.name)">
                    
                </el-tab-pane>
                <component :is="activeName"></component>
            </el-tabs>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        nav: [
          {name: '标段审核记录详情'}
        ],
        tabName: [{
            label: '基本信息',
            name: 'basicInformation',
        }, {
            label: '汇总信息',
            name: 'summaryInformation',
        }, {
            label: '标段合同',
            name: 'noContract',
        }, {
            label: '人员',
            name: 'personnel',
        }, {
            label: '车辆',
            name: 'car',
        }, {
            label: '作业路线网格',
            name: 'workRouteGrid',
        }, {
            label: '城中村',
            name: 'urbanVillages',
        }, {
            label: '市政道路',
            name: 'municipalRoad',
        }, {
            label: '垃圾中转站',
            name: 'wasteTransferStation',
        }, {
            label: '国土地块',
            name: 'thePlot',
        }, {
            label: '垃圾环保屋',
            name: 'greenHouse',
        }, {
            label: '公厕',
            name: 'publicToilets',
        }],
        activeName: 'basicInformation',
      }
    },
    components: {
        basicInformation: resolve => {require(['./component/basic-information'], resolve)},
        summaryInformation: resolve => {require(['./component/summary-information'], resolve)},
        noContract: resolve => {require(['./component/no-contract'], resolve)},
        personnel: resolve => {require(['./component/personnel'], resolve)},
        car: resolve => {require(['./component/car'], resolve)},
        workRouteGrid: resolve => {require(['./component/work-route-grid'], resolve)},
        urbanVillages: resolve => {require(['./component/urban-villages'], resolve)},
        municipalRoad: resolve => {require(['./component/municipal-road'], resolve)},
        wasteTransferStation:  resolve => {require(['./component/waste-transfer-station'], resolve)},
        thePlot: resolve => {require(['./component/the-plot'], resolve)},
        greenHouse: resolve => {require(['./component/green-house'], resolve)},
        publicToilets: resolve => {require(['./component/public-toilets'], resolve)},
    },
    methods: {
      handleClick(tab, event) {
        // console.log(tab, event);
      },
      changeView(val) {
        this.activeName = val
      },
    },
    async mounted () {

    }
  }
</script>

<style lang='sass' scoped>
  /deep/.el-drawer__header 
    margin-bottom: 15px
    padding-left: 15px
    color: #222
    font-size: 16px
  /deep/.el-button--success 
    color: #FFFFFF
    background-color: rgba(26,188,156,1)
    border-color: rgba(26,188,156,1)

    .my-table
        .scope-class
            color: rgba(26,188,156,1)
  /deep/.el-tabs__item.is-active
      color: rgba(26,188,156,1)
  /deep/.el-tabs__active-bar
      background-color: rgba(26,188,156,1)
  /deep/.el-tabs__item:hover
    color: rgba(26,188,156,1)
    cursor: pointer
</style>
